<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Role Management</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <router-link :to="{ name: 'AddRole' }">
        <v-btn
          rounded
          color="primary"
          class="mb-5"
          x-small
          large
          v-if="canAccess('role-create')"
        >
          <v-icon color="white" dark>mdi-plus</v-icon
          ><span style="color: white">Add Role</span>
        </v-btn>
      </router-link>
      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="search_input"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Code</th>
                    <th scope="col">Note</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(role, i) in roles">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ role.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ role.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ role.note }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'RoleEdit',
                                  params: { id: role.id },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="canAccess('role-update')"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-square-edit-outline</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteRole(role.id)"
                                v-if="canAccess('role-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!roles.length">
                    <tr>
                      <td style="text-align: center" colspan="7">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import ApiService from "@/service/api.service";
import _ from "lodash";
export default {
  name: "RoleList",
  components: {
    loading: Loading,
  },
  data() {
    return {
      is_call_api: false,
      roles: [],
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      search_input: "",
    };
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllRole(page, this.search_input);
    },
    search_input: function (val) {
      this.actionSearchTag(1, val);
    },
  },
  created() {
    if (this.canAccess("role-view")) {
      this.is_call_api = true;
      this.getAllRole();
      this.is_call_api = false;
    } else {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllRole(page = 1, query = "") {
      let vm = this;
      await ApiService.get(
        "prep-app/role?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          vm.roles = response.data.data;
          vm.data_pagination = response.data.meta.pagination;
        }
      });
    },
    btnDeleteRole(id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/role/" + id)
            .then(function (res) {
              if (res.status == 202) {
                vm.is_call_api = false;
                vm.getAllRole(vm.data_pagination.current_page, vm.search_input);
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    actionSearchTag: _.debounce(function (page = 1, query = "") {
      this.getAllRole(page, query);
    }, 1200),
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
